import styled from '@emotion/styled'
import { Container, Typography } from '@mui/material'
import React from 'react'

const Introduction = () => {
  return (
    <Container>
      <StyledContainer>
        <StyledVideo>
          <iframe
            width='100%'
            height='100%'
            src='https://player.vimeo.com/video/679766408'
            frameBorder='0'
            allow='fullscreen; picture-in-picture'
            title='Premezclas Introducci&amp;amp;oacute;n'
            loading='lazy'
          />
        </StyledVideo>
        <StyledArticle>
          <Typography variant='title' component='h2'>
            ¿Qué es Fleischmann Virtual?
          </Typography>
          <Typography variant={"text"}>
            Fleischmann virtual es una plataforma de formación gratuita En donde
            los panaderos y pasteleros pueden complementar sus conocimientos
            técnicos y de negocio con la ayuda de nuestros expertos.
          </Typography>
          <Typography variant={"text"}>
            Desde Fleschman virtual puedes encontrar información como:
            capacitaciones en vivo, asesorías de producto y de negocio,
            tendencias, técnicas en preparación y tips. Todo en un solo lugar y
            disponible cuando quieras!.
          </Typography>
        </StyledArticle>
      </StyledContainer>
    </Container>
  )
}

const StyledContainer = styled.div`
  margin: 50px auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  max-width: 640px;
  @media (min-width: 1100px) {
    flex-direction: row;
    max-width: 1250px;
  }
`
const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const StyledVideo = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  /* max-height: 400px; */
`

export default Introduction
