import { Button, Typography } from '@mui/material'
import styled from '@emotion/styled'
import Fields from './PasswordChangeFields'

const PasswordChange = ({ handleClose }) => {
  return (
    <StyledFormContainer>
      <Typography variant='title' component='h2'>
        Cambiar contraseña
      </Typography>
      <Fields Modalclose={handleClose} />
      <Typography
        className="close"
        onClick={handleClose}
        color="primary"
        variant="link"
      >
        Cancelar
      </Typography>
    </StyledFormContainer>
  )
}

const StyledFormContainer = styled.div`
  background: #fff;
  max-width: 550px;
  flex: 1;
  padding: 40px 20px;
  border-radius: 10px;
  color: #114c97;
  text-align: center;
  .close{
    text-transform: uppercase;
  }
`

export default PasswordChange
