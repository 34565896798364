import { useEffect, useState } from 'react'
import { helpHttp } from '../../../helper/helpHttp'

const DynamicFields = () => {
  const { get } = helpHttp()
  const [roles, setRoles] = useState(undefined)
  const [documento, setDocumento] = useState(undefined)

  useEffect(() => {
    getRoles()
    getDocumento()
  }, [])

  const getRoles = () => {
    get(`${process.env.REACT_APP_BASE}catalogos/getAllJob`).then((data) =>
      setRoles(data.datos)
    )
  }

  const getDocumento = () => {
    get(`${process.env.REACT_APP_BASE}catalogos/getAllDocumentType`).then(
      (data) => setDocumento(data.datos)
    )
  }

  return { roles, documento }
}

export default DynamicFields
