import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const BlogItem = ({ blog }) => {
  const date = blog.last_publication_date.slice(0, 10);
  return (
      <StyledCard to={blog.uid}>
        <img
          src={blog.data.featured_image.MD.url}
          alt={blog.data.featured_image.MD.alt}
        />
        <div>
          <p>{date}</p>
          <Typography className="title" variant="title">
            {blog.data.title}
          </Typography>
          <Typography className="info">{blog.data.excerpt}</Typography>
        </div>
      </StyledCard>
  );
};

export default BlogItem;

const StyledCard = styled(Link)`
  text-decoration: none;
  color: unset;
  background-color: #114c971f;
  border-radius: 10px;
  max-height: auto;
  overflow: hidden;
  > img {
    width: 100%;
  }
  > div {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      font-size: 24px;
      line-height: 1;
    }
    .info {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  transition: .2s;
  :hover{
    transition: .2s;
    transform: scale(1.025);
  }
`;
