import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";
import { urlToId } from "../../utils/urlToId";

const CategoryBanner = ({ handleLoaded }) => {
  const [banner, setBanner] = useState(undefined);
  const Params = useParams();
  const URL = Params["*"].split("/");
  const activeCategory = urlToId(URL[URL.length - 1]);
  const { get } = helpHttp();
  useEffect(() => {
    activeCategory !== banner?.id &&
      get(
        `https://escuelaenlineafleischmann.com/categorias/getCategoriaById/${activeCategory}`
      ).then((data) => {
        setBanner(data.datos);
        handleLoaded();
      });
  }, [Params]);

  return (
    <>
      {banner && (
        <StyledBanner img={banner.imagen}>
          <StyledBannerInfo>
            <Typography className="Title" variant="bannerTitle" component="h2">
              {banner.tipo === "3" && (
                <img className="icon" src={banner.icon} />
              )}
              {banner.nombre}
            </Typography>
            <Typography variant="bannerSubTitle" component="h3">
              {banner.descripcion}
            </Typography>
          </StyledBannerInfo>
        </StyledBanner>
      )}
    </>
  );
};
const StyledBanner = styled.div`
  min-height: 250px;
  height: 250px;
  border-radius: 30px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${(props) => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const StyledBannerInfo = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  > h2 {
    margin-bottom: 10px;
    font-size: 1.75rem;
    @media (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
  > h3 {
    font-size: 1rem;
    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }
  .icon {
    width: 80px;
  }
  .Title {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export default memo(CategoryBanner);
