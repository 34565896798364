import styled from "@emotion/styled";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { Dialog, Typography } from "@mui/material";
import SettingsForm from "../../components/Forms/UserSettings";
import useWindowDimensions from "../../hooks/useWidth";
import SettingsIcon from "@mui/icons-material/Settings";
import PasswordChange from "../../components/Forms/PasswordChange";

const UserSettings = () => {
  const width = useWindowDimensions();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <StyledContainer>
      <Container>
        <StyledMain>
          <StyledImageContainer>
            <img
              className="banner"
              src="/images/banner--welcome.jpg"
              alt="Bread"
            />
            {width > 991 && (
              <img
                className="wave"
                src="/images/wave--vertical.svg"
                alt="Bread"
              />
            )}
          </StyledImageContainer>
          <StyledFormContainer>
            <StyledDiv>
              <Typography variant="title" color="white">
                Editar Perfil
              </Typography>
              <StyledTypography
                onClick={handleOpen}
                component="p"
              >
                <SettingsIcon color="secondary" />
                Cambiar contraseña
              </StyledTypography>
            </StyledDiv>
            <SettingsForm />
          </StyledFormContainer>
          <Dialog
            BackdropProps={{ style: { backgroundColor: "#114C97ca" } }}
            disableScrollLock
            open={open}
          >
            <PasswordChange handleClose={handleClose} />
          </Dialog>
        </StyledMain>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background-color: #e5e5e5;
  min-height: calc(100vh - 64px);
  display: flex;
  align-items: center;
`;
const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  > p {
    width: max-content;
  }
  > span {
    font-size: 2rem;
    line-height: 1;
  }
`;
const StyledTypography = styled(Typography)`
  font-size: 1rem;
  text-decoration: underline;
  width: min-content;
  @media (min-width: 768px) {
    font-size: unset;
    width: auto;
  }
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const StyledImageContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .wave {
    width: min-content;
    height: 100%;
    position: absolute;
    right: -1px;
  }
  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }
`;

const StyledFormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #114c97;
  gap: 10px;
`;

const StyledMain = styled.main`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  min-height: 70vh;
  margin: 120px 0 50px 0;
  @media (min-width: 991px) {
    flex-direction: row;
  }
`;

export default UserSettings;
