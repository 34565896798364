import * as Yup from 'yup'

export const PasswordChangeSchema = Yup.object({
  password: Yup.string().required('Requerido'),
  newPassword: Yup.string()
    .required('Requerido')
    .notOneOf(
      [Yup.ref('actualPassword'), null],
      'La contraseña no debe ser igual a la anterior'
    ),
  passwordConfirmation: Yup.string()
    .required('Requerido')
    .oneOf([Yup.ref('newPassword'), null], 'La contraseña no coincide')
})
