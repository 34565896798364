import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, Typography } from "@mui/material";
import styled from "@emotion/styled";

const ModalResponse = ({ open, handleClose, response }) => {
  return (
    <div>
      <Dialog disableScrollLock open={open}>
        <StyledDialog>
          <DialogContent>
            <Typography>{response?.mensaje || "Ocurrió un error, intente mas tarde."}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="invert"
              variant="contained"
              autoFocus
            >
              ok
            </Button>
          </DialogActions>
        </StyledDialog>
      </Dialog>
    </div>
  );
};

const StyledDialog = styled.div`
  display: flex;
  flex-direction: column;
  p{
    font-size: 18px;
  }
  button{
    padding-inline: 30px;
  }

`;

export default ModalResponse;
