import * as Yup from "yup";

export const SettingsSchema = Yup.object({
  foto: Yup.mixed().test({
    message: `La imagen no debe superar los 10Mbs`,
    test: (file) => {
      const isValid = file[0]?.size < 10000000 || !file[0];
      return isValid;
    },
  }),
  nombres: Yup.string().required("Requerido"),
  apellidos: Yup.string().required("Requerido"),
  tipo_documento: Yup.string().required("Requerido"),
  numero_documento: Yup.string().required("Requerido"),
  cargo: Yup.string().required("Requerido"),
  codigo: Yup.string(),
  celular: Yup.string(),
  correo: Yup.string()
    .email("Correo electrónico invalido")
    .required("Requerido"),
});
