import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";
import Seminario from "../Seminario";

const Seminarios = () => {
  const [data, setData] = useState(undefined);
  const [handleLoaded] = useOutletContext();
  const { get } = helpHttp();

  useEffect(() => {
    get("https://escuelaenlineafleischmann.com/seminarios/getSeminarios")
      .then((data) => {
        setData(data.datos);
        handleLoaded();
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <GridData>
      {data?.length > 0 ? (
        data.map((seminario) => (
          <Seminario key={seminario.id} seminario={seminario} />
        ))
      ) : (
        <StyledSeminarios>
          <img src="/images/webinars__404.png" alt="Seminarios" />
          <Typography variant="subtitle">
            Lo sentimos, en el momento no hay seminarios activos
          </Typography>
        </StyledSeminarios>
      )}
    </GridData>
  );
};

export default Seminarios;

const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
  gap: 30px;
`;
const StyledSeminarios = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  > img {
    width: 100px;
  }
  > span {
    padding: 20px 50px;
  }
`;
