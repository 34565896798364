import styled from "@emotion/styled";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";
import { urlToId } from "../../utils/urlToId";

const SubCategoryData = () => {
  const [handleLoaded] = useOutletContext();
  const [data, setData] = useState(undefined);
  const Params = useParams();
  const URL = Params["*"].split("/");
  const activeSubCategory = urlToId(URL[URL.length - 1]);
  const { get } = helpHttp();
  useEffect(() => {
      get(
        `https://escuelaenlineafleischmann.com/categorias/getSubcategorias/${activeSubCategory}`
      )
        .then((data) => {
          setData(data.datos);
          handleLoaded();
        })
        .catch((err) => console.error(err));
  }, [activeSubCategory]);

  return (
    <GridData>
      {data?.length > 0 &&
        data.map((element) => (
          <StyledCard
            key={element.id}
            // onClick={() => handleActiveSubCategory(element.id)}
          >
            <Link to={element.nombre}>
              <CardMedia
                component="img"
                image={element.icon}
                alt={element.nombre}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  component="h3"
                  variant="subtitle"
                  fontWeight={700}
                >
                  {element.nombre}
                </Typography>
                <Typography gutterBottom>{element.descripcion}</Typography>
              </CardContent>
            </Link>
          </StyledCard>
        ))}
    </GridData>
  );
};
const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }
  gap: 10px;
  a {
    text-decoration: none;
    color: inherit;
  }
  > div {
    border-radius: 30px;
    text-align: center;
  }
`;

const StyledCard = styled(Card)`
  padding: 10px 0;
  color: #114c97;
  transition: 0.5s;
  box-shadow: 0px 3px 6px #00000029;
  h3 {
    margin-bottom: 10px;
  }
  :hover {
    transition: 0.5s;
    background-color: #114c97;
    color: floralwhite;
    cursor: pointer;
  }
  img {
    height: 100px;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;

export default SubCategoryData;
