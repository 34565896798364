/* eslint-disable camelcase */
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import styled from "@emotion/styled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SignUpSchema } from "./Schema";
import DynamicFields from "./DynamicFields";
import { useUser } from "../../../hooks/useUser";
import { helpHttp } from "../../../helper/helpHttp";
import ModalResponse from "../../ModalResponse";

const Fields = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [open, setOpen] = useState(false);
  const [response, setResponse] = useState(undefined);
  const { roles, documento } = DynamicFields();
  const { logInUser } = useUser();
  const { post } = helpHttp();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all", resolver: yupResolver(SignUpSchema) });
  
  const handleClose = () => {
    setOpen(false);
  };
  
  const onSubmit = (data) => {
    post("https://escuelaenlineafleischmann.com/usuarios/register", {
      body: data,
    }).then((response) => {
      response.codigo === "200" && logInUser(response.datos);
      setResponse(response);
      setOpen(true);
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <TextField
        autoComplete="no"
        variant="standard"
        error={!!errors.nombres?.message}
        helperText={errors.nombres?.message}
        label="Nombre"
        {...register("nombres")}
      />
      <TextField
        autoComplete="no"
        variant="standard"
        error={!!errors.apellidos?.message}
        helperText={errors.apellidos?.message}
        label="Apellidos"
        {...register("apellidos")}
      />
      <FormControl variant="standard">
        <InputLabel error={!!errors.tipo_documento?.message}>
          Tipo de documento
        </InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          align="left"
          defaultValue=""
          error={!!errors.tipo_documento?.message}
          label="Tipo de documento"
          {...register("tipo_documento")}
        >
          {documento?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nombre}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{errors.tipo_documento?.message}</FormHelperText>
      </FormControl>
      <TextField
      autoComplete="off"
        variant="standard"
        error={!!errors.numero_documento?.message}
        helperText={errors.numero_documento?.message}
        label="Número de documento"
        {...register("numero_documento")}
      />
      <TextField
      autoComplete="off"
        variant="standard"
        error={!!errors.correo?.message}
        helperText={errors.correo?.message}
        label="Correo electrónico"
        type="email"
        {...register("correo")}
      />
      <TextField
      autoComplete="off"
        variant="standard"
        error={!!errors.emailConfirmation?.message}
        helperText={errors.emailConfirmation?.message}
        label="Confirmar correo electrónico"
        {...register("emailConfirmation")}
        type="email"
      />
      <TextField
      autoComplete="off"
        variant="standard"
        error={!!errors.password?.message}
        helperText={errors.password?.message}
        label="Contraseña"
        {...register("password")}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                edge="end"
                // color="primary"
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
      autoComplete="off"
        variant="standard"
        error={!!errors.passwordConfirmation?.message}
        helperText={errors.passwordConfirmation?.message}
        label="Confirmar contraseña"
        {...register("passwordConfirmation")}
        type={showPassword ? "text" : "password"}
      />
      <FormControl variant="standard">
        <InputLabel error={!!errors.cargo?.message}>Cargo</InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          defaultValue=""
          align="left"
          error={!!errors.cargo?.message}
          label="Cargo"
          {...register("cargo")}
        >
          {roles?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nombre}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{errors.cargo?.message}</FormHelperText>
      </FormControl>
      <TextField
      autoComplete="off"
        variant="standard"
        error={!!errors.celular?.message}
        helperText={errors.celular?.message}
        {...register("celular")}
        label="Celular"
      />
      <StyledFormOptionsContainer>
        <StyledFormOptions>
          <FormControl>
            <Checkbox color="secondary" {...register("terms")} />
          </FormControl>
          <Typography>
            Acepto los{" "}
            <Link
              variant="text"
              rel="external noreferrer"
              href="./docs/fleischmann_tyc.pdf"
              target="_blank"
            >
              Términos, Condiciones
            </Link>{" "}
            y{" "}
            <Link
              variant="text"
              rel="external noreferrer"
              target="_blank"
              href="./docs/fleischmann_politica.pdf"
            >
              Políticas del sitio.
            </Link>
          </Typography>
        </StyledFormOptions>
        <FormHelperText error>{errors.terms?.message}</FormHelperText>
      </StyledFormOptionsContainer>
      <StyledButton color="secondary" type="submit" variant="contained">
        Ingresar
      </StyledButton>
      <ModalResponse
        open={open}
        response={response}
        handleClose={handleClose}
      />
    </StyledForm>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  justify-self: center;
  @media (min-width: 768px) {
    grid-column: auto / span 2;
  }
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  gap: 15px;
  p {
    font-size: 1rem;
  }
`;

const StyledFormOptionsContainer = styled.div`
  @media (min-width: 768px) {
    grid-column: auto / span 2;
  }
`;

const StyledFormOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: left;
`;

export default Fields;
