import React from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import styled from '@emotion/styled'
import { TestSchema } from './Schema'
import { useCountDown } from '../../../hooks/useCountDown'

export const TestFields = ({ question, handleResponse, last }) => {
  const TIEMPO_MAXIMO = 5
  const normalise = (value) => ((value - 0) * 100) / (TIEMPO_MAXIMO - 0)

  const {
    getValues,
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'all', resolver: yupResolver(TestSchema) })

  const onSubmit = (data) => {
    const idRespuesta = getValues('idRespuesta')
    const respuesta = {
      idPregunta: question?.idPregunta,
      idRespuesta: idRespuesta || '0'
    }
    handleResponse(respuesta)
    reset()
  }
  const { counter } = useCountDown(TIEMPO_MAXIMO, onSubmit, question)
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <RadioGroup
        aria-labelledby='demo-radio-buttons-group-label'
        name='radio-buttons-group'
      >
        {question?.respuestas.map((value) => (
          <FormControlLabel
            key={value.idRespuesta}
            {...register('idRespuesta')}
            value={value.idRespuesta}
            control={<Radio />}
            label={value.respuesta}
          />
        ))}
      </RadioGroup>
      <p>{errors?.idRespuesta?.message}</p>
      <StyledButton color='primary' type='submit' variant='contained'>
        {last ? 'Finalizar' : 'Siguiente'}
      </StyledButton>
      <LinearProgress
        variant='determinate'
        color='primary'
        value={normalise(counter)}
      />
    </StyledForm>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: 10px;
  flex-direction: column;
  p {
    font-size: 1rem;
    color: red;
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  align-self: center;
`
