import styled from '@emotion/styled'
import { List } from '@mui/material'
import React from 'react'
import { UnLoggedLinks } from '../Links'

const DesktopMenu = () => {
  return (
    <StyledMenu>
      <UnLoggedLinks />
    </StyledMenu>
  )
}

const StyledMenu = styled.ul`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`
export default DesktopMenu
