import styled from '@emotion/styled'
import { Link, Typography } from '@mui/material'
import React from 'react'
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'

const Footer = () => {
  return (
    <StyledFooter>
      <Link
        underline='hover'
        color='inherit'
        rel='external noreferrer'
        target='_blank'
        href='tel:018000112223'
      >
        <div className='composedlink'>
          <LocalPhoneRoundedIcon />
          <Typography variant='link'>01 8000 11 2223</Typography>
        </div>
      </Link>
      <Link
        underline='hover'
        color='inherit'
        rel='external noreferrer'
        target='_blank'
        href='https://www.fleischmanncolombia.com.co'
      >
        <div className='composedlink'>
          <PublicRoundedIcon />
          <Typography variant='link'>www.fleischmanncolombia.com.co</Typography>
        </div>
      </Link>
      <Typography>
        Copyright © 2022 Fleischmann, Todos los derechos reservados.
      </Typography>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-color: #114c97;
  padding: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 50px;
  text-align: center;

  .composedlink {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
  }
`

export default Footer
