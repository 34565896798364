import styled from "@emotion/styled";
import React from "react";
import BackArrow from "../BackArrow";

const CategoryContainer = ({ children }) => {
  return (
    <StyledData>
      <BackArrow />
      {children}
    </StyledData>
  );
};

export default CategoryContainer;

const StyledData = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #f7f7f7;
  padding: 50px 10px 10px 10px;
  @media (min-width: 768px) {
    padding: 50px 30px;
  }
  //First child is Back arrow
  .arrow {
    position: absolute;
    top: 15px;
    left: 15px;
  }
`;
