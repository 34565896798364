import { createTheme } from '@mui/material'

export const theme = createTheme({
  // -----------------------------------------------//CONFIGURACION DE COLORES//-----------------------------------------------
  palette: {
    primary: {
      main: '#114c97'
    },
    secondary: {
      main: '#ffcc00',
      contrastText: '#114c97'
    },
    invert: {
      main: '#EBEBEB',
      contrastText: '#114c97'
    }
  },
  //  -----------------------------------------------//CONFIGURACION DE BREAKPOINTS//-----------------------------------------------
  breakpoints: {
    values: {
      xs: 0, // 0 a 599
      sm: 600, // 600 a 899
      md: 900, // 900 a 1499
      lg: 1500, // 1500 a 1536
      xl: 1536
    }
  },
  // -----------------------------------------------// CONFIGURACION DE FUENTES //-----------------------------------------------
  typography: {

    text: {
      fontFamily: "sans-serif",
      lineHeight: '1.5'
    },
    body: {
      fontFamily: "sans-serif"
    },
    body1: {
      // fontSize: "1.1rem",
      // lineHeight: "1.3",
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: '700',
      color: '#114c97',
      lineHeight: '1.2'
    },
    bannerTitle: {
      fontSize: '4rem',
      fontWeight: '700',
      color: 'white',
      lineHeight: '1.2'
    },
    bannerSubTitle: {
      fontSize: '2rem',
      fontWeight: '500',
      color: 'white',
      lineHeight: '1.2'
    },
    subtitle: {
      fontSize: '1.25rem',
      fontWeight: '500',
      lineHeight: '1.2'
    },
    button: {
      fontSize: '1rem',
      fontWeight: '700'
    },
    link: {
      fontSize: '1rem',
      fontWeight: '700',
      cursor:"pointer"
    },
    fontFamily: [
      'sans-serif',
      'AvantGarde Bk BT',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  }
})
