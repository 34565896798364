import React from "react";
import { useParams } from "react-router-dom";
import { PrismicRichText, usePrismicDocumentByUID } from "@prismicio/react";
import { Container } from "@mui/system";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import BackArrow from "../../components/BackArrow";

const BlogPage = () => {
  const { BlogId } = useParams();
  const [document] = usePrismicDocumentByUID("blog_post", BlogId);
  return (
    <Container>
      <StyledBlog>
        <header>
          <BackArrow />
          <hr />
          <div className="data">
            <div className="image">
              <img
                src={document?.data.featured_image.MD.url}
                alt={document?.data.featured_image.MD.alt}
              />
            </div>
            <div className="info">
              <Typography component={"h2"} variant="title">
                {document?.data.title}
              </Typography>
              <Typography component={"h3"} variant="subtitle">
                {document?.data.excerpt}
              </Typography>
            </div>
          </div>
        </header>
        <hr />
        <PrismicRichText field={document?.data.content} />
      </StyledBlog>
    </Container>
  );
};

export default BlogPage;

const StyledBlog = styled.div`
  min-height: calc(100vh - 64px);
  margin: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  padding-block: 120px 20px;
  gap: 20px;
  header {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  p,
  h3 {
    font-family: sans-serif;
  }
  > div {
    width: 100%;
    aspect-ratio: 16/9;
    > iframe {
      width: 100%;
      height: 100%;
    }
  }
  img {
    width: 100%;
  }
  .data {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .image {
      flex-basis: 500px;
      flex-grow: 1;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .info {
      flex: 2;
      flex-basis: 500px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      > h2 {
        line-height: 1;
        font-size: 30px;
      }
    }
  }
`;
