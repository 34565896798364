import React from "react";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import styled from "@emotion/styled";
import { Dialog, Typography } from "@mui/material";

const SeminarioInfo = ({ seminario, open, handleClose }) => {
  return (
    <StyledDialog
      fullWidth={true}
      maxWidth={"xl"}
      BackdropProps={{ style: { backgroundColor: "#114C97ca" } }}
      open={open}
    >
      <Typography variant="title" className="cross" onClick={handleClose}>
        x
      </Typography>
      {seminario ? (
        <StyledDiv>
          <div className="info">
            <Typography
              className="subtitle"
              component={"h2"}
              variant="subtitle"
            >
              Información del curso:
            </Typography>
            <div>
              <Typography>
                <WatchLaterOutlinedIcon color="secondary" /> Duracion:{" "}
                {seminario?.duracion} min
              </Typography>
              <Typography>
                <SignalCellularAltOutlinedIcon color="secondary" /> Nivel:{" "}
                {seminario?.nivel}
              </Typography>
            </div>
          </div>
          <div className="title">
            <Typography component={"h1"} variant="title">
              {seminario.nombre}
            </Typography>
            <Typography>{seminario.descripcion}</Typography>
          </div>
          <div className="details">
            <Typography className="subtitle" variant="subtitle">
              ¿Qué aprenderás en este curso?
            </Typography>
            <GridData>
              {seminario?.aprendizajes?.map((e) => (
                <div>
                  <CheckOutlinedIcon color="secondary" />
                  <Typography>{e.item}</Typography>
                </div>
              ))}
            </GridData>
          </div>
        </StyledDiv>
      ) : (
        <Typography component={"h2"} className="error" variant="title">
          No se ha encontrado informacion del seminario intentelo mas tarde
        </Typography>
      )}
    </StyledDialog>
  );
};

export default SeminarioInfo;

const StyledDiv = styled.div`
  @media (min-width: 768px) {
    padding: 50px;
  }
  padding: 40px 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .info {
    padding: 15px;
    background-color: #f6f7f9;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 50px;
      > p {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 15px;
    > h1 {
      @media (max-width: 768px) {
        font-size: 26px;
      }
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .subtitle {
    color: #114c97;
    font-size: 20px;
    @media (min-width: 768px) {
      font-size: 30px;
    }
    font-weight: 700;
  }
`;
const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 30px;
  > div {
    display: flex;
    gap: 10px;
    > svg {
      font-size: 30px;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  .cross {
    cursor: pointer;
    font-size: 30px;
    line-height: 0.25;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .error{
    padding: 40px;
    @media (max-width: 768px) {
        font-size: 26px;
      }
  }
`;
