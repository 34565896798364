import { useEffect, useState } from 'react'
import { helpHttp } from '../helper/helpHttp'

const useTest = (token) => {
  const [reset, setReset] = useState(false)
  const [start, setStart] = useState(false)
  const [test, setTest] = useState(undefined)
  const [actualQuestion, setActualQuestion] = useState(undefined)
  const { post, get } = helpHttp()
  const initialState = { idEvaluacion: 1, respuestas: [] }
  const [response, setResponse] = useState(initialState)
  const [calificacion, setCalificacion] = useState(undefined)

  useEffect(() => {
    // Onpopstate observa el evento de back button y beforeunload el de refresh y el de cerrar con la cruz
    window.onpopstate = handleSendIncompleteTest
    window.addEventListener('beforeunload', handleSendIncompleteTest)
    return () => {
      window.removeEventListener('beforeunload', handleSendIncompleteTest)
    }
  }, [calificacion, start, response])

  // Cuando se ingresa se cargan las respuestas que ya tenia el usuario y rellena las qeu faltan con 0
  const handleSendIncompleteTest = () => {
    const newResponse = { ...response }
    let lastResponse = newResponse?.respuestas?.length
    if (!calificacion && start) {
      while (lastResponse < test?.length) {
        newResponse.respuestas.push({
          idPregunta: test[lastResponse].idPregunta,
          idRespuesta: '0'
        })
        lastResponse++
      }
      post(
        'https://escuelaenlineafleischmann.com/evaluaciones/registrarEvaluacion',
        { body: newResponse, headers: { Authorization: token } }
      )
      alert('Se ha enviado la evaluación incompleta')
    }
  }

  useEffect(() => {
    // Cuando las cantidad de respuestas es igual a la cantidad de preguntas se envian con el metodo Post
    response?.respuestas.length === test?.length &&
      post(
        'https://escuelaenlineafleischmann.com/evaluaciones/registrarEvaluacion',
        { body: response, headers: { Authorization: token } }
      ).then((res) => {
        setCalificacion(res.datos)
        setResponse(initialState)
        setStart(false)
        // alert('respuestas enviadas correctamente')
      })
  }, [response])

  useEffect(() => {
    // Cuando se inicia el test se cargan las preguntas por el momento la evaluacion 1
    get(
      'https://escuelaenlineafleischmann.com/evaluaciones/getEvaluacionByModulo/1',
      { headers: { Authorization: token } }
    ).then((data) => {
      setTest(data.datos)
      setActualQuestion({
        number: 1,
        question: data.datos[0]
      })
    })
  }, [reset])

  const handleResponse = (resp) => {
    setResponse((prev) => ({
      ...prev,
      respuestas: [...prev.respuestas, resp]
    }))
    // if (actualQuestion?.number === test.length) {
    //   setStart(false)
    // }
    actualQuestion?.number < test.length &&
      setActualQuestion({
        number: actualQuestion.number + 1,
        question: test[actualQuestion.number]
      })
  }

  return {
    handleResponse,
    setReset,
    calificacion,
    actualQuestion,
    setCalificacion,
    test,
    start,
    setStart
  }
}

export default useTest
