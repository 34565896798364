import styled from "@emotion/styled";
import { Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Profile from "../Profile";
import { useUser } from "../../hooks/useUser";
import { Button } from "@mui/material";
import MobileMenu from "../Menu/MobileMenu";
import DesktopMenu from "../Menu/DesktopMenu";
import useWindowDimensions from "../../hooks/useWidth";
import MainLink from "../MainLink";
import { useDispatch } from "react-redux";
import { reset } from "../../redux/slices/category";

const Header = () => {
  const { user, logOutUser } = useUser();
  const dispatch = useDispatch();
  const returnHome = () => {
    dispatch(reset());
  };

  const width = useWindowDimensions();

  return (
    <Layout user={user.nombres}>
      <Container>
        <Nabvar>
          <ImageContainer onClick={returnHome}>
            <Link to="/">
              <img src="/images/logo.png" alt="logo" />
            </Link>
          </ImageContainer>
          {user.nombres ? (
            <>
              <MainLink returnHome={returnHome} />
              <Profile user={user} logOutUser={logOutUser} />
            </>
          ) : (
            <DesktopMenu />
          )}
          {width <= 768 && <MobileMenu user={user} logOutUser={logOutUser} />}
        </Nabvar>
      </Container>
    </Layout>
  );
};

const Nabvar = styled.header`
  height: 70px;
  @media (min-width: 768px) {
    height: 100px;
    padding: 10px 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ImageContainer = styled.div`
  max-width: 90px;
  flex: 1;
  margin-right: auto;
  height: 100%;
  @media (min-width: 768px) {
    max-width: 150px;
    margin-right: unset;
  }
  cursor: pointer;
`;

const Layout = styled.header`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  /* overflow-x: hidden; */
  background-color: ${(props) =>
    props.user ? "#114c97" : "rgba(255,255,255,0.5)"};
  backdrop-filter: ${(props) => !props.user && "blur(2px)"};
`;

export const StyledButton = styled(Button)`
  font-weight: 700;
  font-size: 1rem;
`;

export default Header;
