import styled from "@emotion/styled";
import React from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { changeSubCategory } from "../../redux/slices/category";


const BackArrow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleActiveSubCategory = (id) => {
    dispatch(changeSubCategory(id));
  };
  return (
    <StyledBackPage
        className="arrow"
      onClick={() => {
        navigate(-1);
        handleActiveSubCategory(undefined);
      }}
    >
      <ArrowBackRoundedIcon color="secondary" />
      <Typography variant="text" component={"a"} color="primary">
        Retroceder
      </Typography>
    </StyledBackPage>
  );
};

export default BackArrow;

const StyledBackPage = styled.div`
  display: flex;
  :hover {
    text-decoration: underline;
  }
  gap: 5px;
  align-items: center;
  cursor: pointer;
  width: max-content;
  > a {
    text-decoration: none;
  }
`;
