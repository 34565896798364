import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { helpHttp } from "../../helper/helpHttp";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { useOutletContext, useParams } from "react-router-dom";
import { urlToId } from "../../utils/urlToId";

const ProductData = () => {
  const [data, setData] = useState(undefined);
  const [handleLoaded] = useOutletContext();
  const { producto } = useParams();
  const { get } = helpHttp();
  useEffect(() => {
    const id = urlToId(producto);
    get(
      `https://escuelaenlineafleischmann.com/categorias/getDetailsCategoria/${id}`
    )
      .then((data) => {
        setData(data);
        handleLoaded();
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <StyledList>
      {data?.datos?.map((e) => (
        <StyledCard key={e.id}>
          <StyledVideo>
            <iframe
              width="100%"
              height="100%"
              src={e.url_video}
              frameBorder="0"
              loading="lazy"
              allow="fullscreen; picture-in-picture"
            />
          </StyledVideo>
          <StyledInfo>
            <Typography component={"h3"} variant="title">
              {e.titulo}
            </Typography>
            <div>
              <Typography>{e.descripcion}</Typography>
              <StyledAnchor href={e.url_pdf} download={`${e.titulo}.pdf`}>
                Descargar
                <PictureAsPdfOutlinedIcon />
              </StyledAnchor>
            </div>
          </StyledInfo>
        </StyledCard>
      ))}
      {data?.codigo === "404" && (
        <Typography component={"h4"} variant="title">
          No se encontraron resultados
        </Typography>
      )}
    </StyledList>
  );
};

export default ProductData;

const StyledAnchor = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #ffcc00;
  width: max-content;
  padding: 5px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-family: sans-serif;
  color: #114c97;
  margin-top: 15px;
`;
const StyledVideo = styled.div`
  aspect-ratio: 16/9;
  flex: 1;
  flex-basis: 360px;
  @media (min-width: 1100px) {
    max-width: 400px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  @media (min-width: 768px) {
    padding: 30px;
  }
  flex-wrap: wrap;
  width: 100%;
  gap: 10px 20px;
`;
const StyledInfo = styled.div`
  display: flex;
  flex: 2;
  flex-basis: 360px;
  flex-direction: column;
  gap: 10px;
  > h3 {
    font-size: 26px;
  }
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  > h4 {
    text-align: center;
    font-size: 2rem;
  }
`;
