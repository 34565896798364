import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";

const Redes = () => {
  const [handleLoaded] = useOutletContext();
  const [data, setData] = useState(undefined);
  const { get } = helpHttp();
  useEffect(() => {
    get(`https://escuelaenlineafleischmann.com/categorias/getSubcategorias/3`)
      .then((data) => {
        setData(data.datos);
        handleLoaded();
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <StyledRedes>
      <Typography variant="subtitle">
        Lo sentimos, en el momento no hay Redes activas
      </Typography>
    </StyledRedes>
  );
};

export default Redes;

const StyledRedes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  > img {
    width: 100px;
  }
  > span {
    padding: 20px 50px;
  }
`;
