import styled from "@emotion/styled";
import { Container } from "@mui/system";
import { usePrismicDocuments } from "@prismicio/react";
import React from "react";
import BlogItem from "../../components/BlogItem";

const BlogList = () => {
  const documents = usePrismicDocuments();
  const { results } = documents[0] || [];
  return (
    <StyledContainer>
      {results?.map((blog) => (
        <BlogItem key={blog.uid} blog={blog} />
      ))}
    </StyledContainer>
  );
};

export default BlogList;

const StyledContainer = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-block: 150px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(300px, max-content));
  gap: 20px;
`;
