import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import CategoryBanner from "../../components/CategoryBanner";
import CategoryNav from "../../components/CategoryNav";
import Loader from "../../components/Loader";
import {
  changeCategory,
  changeLoading,
  changeSubCategory,
} from "../../redux/slices/category";
import { urlToId } from "../../utils/urlToId";

const Main = () => {
  const dispatch = useDispatch();
  const { activeSubCategory, activeCategory, loadingData } = useSelector(
    (state) => state.category
  );
  const Params = useParams();
  const URL = Params["*"].split("/");
  useEffect(() => {
    const newCategory = urlToId(URL[0]);
    activeCategory !== newCategory && dispatch(changeCategory(newCategory));
    if (URL.length > 1) {
      const newSubCategory = urlToId(URL[URL.length - 1]) || 0;
      activeSubCategory !== newSubCategory &&
        dispatch(changeSubCategory(newSubCategory || 1));
    }
  }, [Params]);

  const handleLoaded = () => {
    dispatch(changeLoading());
  };

  return (
    <Container>
      <StyledMain>
        <CategoryNav activeCategory={activeCategory} />
        <StyledContent>
          {loadingData && <Loader />}
          <StyledContent loading={loadingData ? "true" : "" }>
            <CategoryBanner
              handleLoaded={handleLoaded}
            />
            <Outlet context={[handleLoaded, activeSubCategory]} />
          </StyledContent>
        </StyledContent>
      </StyledMain>
    </Container>
  );
};

const StyledMain = styled.main`
  display: flex;
  gap: 20px;
  margin: 120px 0 50px 0;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const StyledContent = styled.div`
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  display: ${(props) => (props.loading ? "none" : "flex")};
  flex-direction: column;
  flex: 1;
  gap: 20px;
  min-height: 50vh;
`;
export default Main;
