import styled from "@emotion/styled";
import { Card, CardContent, CardMedia, css, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useOutletContext } from "react-router-dom";
import { helpHttp } from "../../helper/helpHttp";
import { useUser } from "../../hooks/useUser";
import { RefreshToken } from "../../redux/slices/user";

const Experiencia = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(undefined);
  const [handleLoaded] = useOutletContext();
  const { get, post } = helpHttp();
  const { user } = useUser();
  useEffect(() => {
    get(
      `https://escuelaenlineafleischmann.com/modulos/getModulosByCategoria/10`,
      { headers: { Authorization: user.token } }
    )
      .then((data) => {
        if (data.status === 401) {
          post(`https://escuelaenlineafleischmann.com/usuarios/refreshtoken`, {
            body: { refreshToken: user.refreshToken },
          }).then((data) => {
            dispatch(RefreshToken(data.datos))
          });
        }
        setData(data.datos);
        handleLoaded();
      })
      .catch((err) => {});
  }, [user.token]);
  return (
    <GridData>
      {data?.length > 0 &&
        data.map((element) => (
          <StyledCard estado={element.estado} key={element.id}>
            {element?.estado === "habilitado" ? (
              <Link to={element.nombre}>
                <CardMedia
                  component="img"
                  image={element.icon || "/images/auth__bread.jpg"}
                  alt={element.nombre}
                />
                <StyledCardContent>
                  <Typography gutterBottom variant="subtitle" fontWeight={700}>
                    {element.nombre}
                  </Typography>
                </StyledCardContent>
              </Link>
            ) : (
              <>
                <CardMedia
                  component="img"
                  image={element.icon || "/images/auth__bread.jpg"}
                  alt={element.nombre}
                />
                <StyledCardContent>
                  <Typography gutterBottom variant="subtitle" fontWeight={700}>
                    {element.nombre}
                  </Typography>
                </StyledCardContent>
              </>
            )}
          </StyledCard>
        ))}
    </GridData>
  );
};

export default Experiencia;

const GridData = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
  gap: 10px;
  a {
    text-decoration: none;
    color: inherit;
  }
  > div {
    border-radius: 30px;
    text-align: center;
  }
`;

const StyledCard = styled(Card)`
  padding: 40px 0px;
  height: 100%;
  color: #114c97;
  transition: 0.5s;
  box-shadow: 0px 3px 6px #00000029;
  filter: grayscale(100%);
  ${(props) =>
    props.estado !== "deshabilitado" &&
    css`
      filter: grayscale(0%);
      :hover {
        transition: 0.5s;
        background-color: #114c97;
        color: floralwhite;
        cursor: pointer;
      }
    `}
  img {
    height: 100px;
    aspect-ratio: 1;
    object-fit: contain;
  }
`;
const StyledCardContent = styled(CardContent)`
  display: flex;
  height: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
