import { Link as ReactLink } from 'react-router-dom'
import { Typography, Link } from '@mui/material'
import styled from '@emotion/styled'
import Fields from './SignUpFields'

const SignUpForm = () => {
  return (
    <StyledFormContainer>
      <Fields />
      <StyledRegister>
        <Typography>¿Tienes una cuenta?</Typography>
        <Link component={ReactLink} variant="text" to='/sign-in'>
          Iniciar sesión
        </Link>
      </StyledRegister>
    </StyledFormContainer>
  )
}

const StyledFormContainer = styled.div`
  background: #fff;
  /* max-width: 550px; */
  flex: 1;
  padding: 20px 20px 40px 20px ;
  border-radius: 10px;
  color: #114c97;
  text-align: center;
`

const StyledRegister = styled.div`
  justify-content: center;
  margin-top: 20px;
  @media (min-width: 768px) {
    grid-column: auto / span 2;
  }
  align-items: center;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`

export default SignUpForm
