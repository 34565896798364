import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import MenuIcon from '@mui/icons-material/Menu'
import styled from '@emotion/styled'
import { LoggedLinks, UnLoggedLinks } from '../Links'

const MobileMenu = ({ user, logOutUser }) => {
  const [state, setState] = useState(false)
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState(open)
  }
  return (
    <StyledMenu user={user.nombres}>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ fontSize: '40px' }} />
      </Button>
      <Drawer
        disableScrollLock
        anchor='top'
        open={state}
        onClose={toggleDrawer(false)}
      >
        <Box
          // sx={{ width: 250 }} definir ancho si el anchor es right o left
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <StyledList>
            {user.nombres
              ? (
                <LoggedLinks mobile logOutUser={logOutUser} />
                )
              : (
                <UnLoggedLinks mobile />
                )}
          </StyledList>
        </Box>
      </Drawer>
    </StyledMenu>
  )
}

const StyledList = styled(List)`
padding: 0;
`
const StyledMenu = styled.div`
  svg {
    color: ${(props) => (props.user ? 'white' : '#114c97')};
  }
  @media (min-width: 768px) {
    display: none;
  }
`

export default MobileMenu
