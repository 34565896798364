import { useSelector, useDispatch } from 'react-redux'
import { logIn, logOut } from '../redux/slices/user'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const useUser = () => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  // Dispatch actions

  const logInUser = (payload) => {
    dispatch(logIn(payload))
    navigate('../main', { replace: true })
  }

  const logOutUser = () => {
    dispatch(logOut())
  }

  //

  return { user, logInUser, logOutUser }
}
