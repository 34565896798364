import styled from '@emotion/styled'
import Fields from './SettingsFields'

const SignUpForm = () => {
  return (
    <StyledFormContainer>
      <Fields />
    </StyledFormContainer>
  )
}

const StyledFormContainer = styled.div`
  background: #fff;
  width: 100%;
  /* max-width: 550px; */
  /* flex: 1; */
  padding: 40px 20px;
  border-radius: 10px;
  color: #114c97;
  text-align: center;
`
export default SignUpForm
