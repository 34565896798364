import { Typography } from '@mui/material'
import styled from '@emotion/styled'
import Fields from './SignValidateFields'

const SignValidateForm = () => {
  return (
    <StyledFormContainer>
      <Typography variant='title' component='h2'>
        Validación Código
      </Typography>
      <Typography variant='text'>
        Ingresa el código que fue enviado a tu correo para crear tu nueva contraseña
      </Typography>
      <Fields />
    </StyledFormContainer>
  )
}

const StyledFormContainer = styled.div`
  background: #fff;
  max-width: 550px;
  flex: 1;
  padding: 40px 20px;
  border-radius: 10px;
  color: #114c97;
  > h2{margin-bottom: 20px;}
  > span{font-size:20px;
  line-height:1.25}
`

export default SignValidateForm
