import styled from "@emotion/styled";
import { Button, Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import { helpHttp } from "../../helper/helpHttp";

import SeminarioInfo from "../SeminarioInfo";

const Seminario = ({ seminario }) => {
  const { get } = helpHttp();
  const [open, setOpen] = useState(false);
  const [seminarioData, setSeminarioData] = useState(undefined);

  const getSeminario = (id) => {
    get(
      "https://escuelaenlineafleischmann.com/seminarios/getSeminarioById/" + id
    ).then((data) => {
      setSeminarioData(data.datos);
      setOpen(true);
    });
  };

  const handleOpen = (id) => {
    getSeminario(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledCard estado={seminario.estado} key={seminario.id}>
        <img src={seminario.imagen} alt={seminario.nombre} />
        <StyledCardContent>
          <Typography variant="subtitle" fontWeight={700}>
            {seminario.nombre}
          </Typography>
          <Typography variant="text" color={"black"} fontWeight={700}>
            Por {seminario.por}
          </Typography>
          <StyledDate>
            <Typography variant="caption" fontWeight={700}>
              Inicio: {seminario.fecha}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              Hora: {seminario.hora}hs
            </Typography>
          </StyledDate>
          <StyledButtons>
            <Button color="secondary" variant="contained">
              Inscribirse
            </Button>
            <Typography
              onClick={() => handleOpen(seminario.id)}
              variant="caption"
              fontWeight={700}
            >
              Leer más
            </Typography>
          </StyledButtons>
        </StyledCardContent>
      </StyledCard>
      <SeminarioInfo seminario={seminarioData} open={open} handleClose={handleClose} />
    </>
  );
};

export default Seminario;


const StyledCard = styled.div`
  color: #114c97;
  img {
    width: 100%;
    max-height: 200px;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 30px;
  }
`;

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const StyledDate = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  > span {
    line-height: 1;
  }
`;

const StyledButtons = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
  > button {
    text-transform: capitalize;
    flex: 2;
  }
  > span {
    flex: 1;
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
`;
