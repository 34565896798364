import styled from '@emotion/styled'
import { DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

const TestResult = ({ calificacion }) => {
  return (
    <>
      <StyledDialogTitle component='div'>
        <Typography variant='title' color='primary'>
          {calificacion.aprobado ? '¡Felicidades!' : 'Lo sentimos'}
        </Typography>
        <div>
          <Typography variant='subtitle' fontWeight={700}>
            PUNTAJE
          </Typography>
          <Typography variant='subtitle' fontWeight={700} color='primary'>
            {calificacion.exitosas}/{calificacion.configuradas}
          </Typography>
        </div>
      </StyledDialogTitle>
      <DialogContent>
        <Typography component='p'>
          {' '}
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
          ut tristique ex. Phasellus sed vehicula nunc, non sagittis ex.
          Class aptent taciti sociosqu ad litora torquent per conubia
          nostra, per inceptos himenaeos.
        </Typography>
      </DialogContent>
    </>
  )
}

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  > div {
    display: flex;
    flex-direction: column;
  }
  text-align: center;
`
export default TestResult
